import { Flex, FlexProps, Image } from "@chakra-ui/react";
import clsx from "clsx";

import { previewNode, setPreviewNode } from "@/features/workflow-studio";
import { NodeType } from "@/features/workflow-studio/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";

import { categoryIcons } from "../../utils/constants";

export const BaseNode = ({
  node,
  className = "",
  draggable = true,
  index,
  ...props
}: {
  className?: string;
  node: NodeType;
  index: number;
} & FlexProps) => {
  const dispatch = useAppDispatch();
  const currentNode = useAppSelector(previewNode);

  const showNodePreview = (activeNode: NodeType | null) => {
    dispatch(setPreviewNode(activeNode));
  };

  const onDragStart = (
    event: React.DragEvent,
    nodeVersionId: string,
    nodeType: string,
    nodeGroup: string
  ) => {
    event.dataTransfer.setData("application/nodeIdx", nodeVersionId);
    event.dataTransfer.setData("application/nodeType", nodeType);
    event.dataTransfer.setData("application/nodeGroup", nodeGroup);
    event.dataTransfer.setData("application/nodeIndex", index.toString());
    event.dataTransfer.effectAllowed = "move";
  };

  // const nodeCategory: string = toCaptialSnakeCase(node.nodeCategory);

  return (
    <Flex
      className={clsx(
        `h-full w-full cursor-grab gap-3 p-3 bg-white rounded hover:bg-orange-50 hover:border-gray-600`,
        draggable && "border !h-[140px] !w-[160px] !cursor-pointer !bg-gray-50",
        draggable && currentNode?.nodeVersionId == node.nodeVersionId
          ? "bg-orange-50 border-gray-600"
          : " border-gray-400",
        className
      )}
      direction="column"
      draggable={draggable}
      onDragStart={(event: React.DragEvent) =>
        draggable &&
        onDragStart(event, node.nodeVersionId, node.nodeType, node.nodeCategory)
      }
      onMouseEnter={() => showNodePreview(node)}
      {...props}
    >
      <Image
        className="rounded"
        boxSize="2rem"
        p={1.5}
        bg={categoryIcons[node.nodeCategory].theme}
        alt="Fluffybuns the destroyer"
        draggable={false}
        src={
          node.imageUrl ??
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Globe_icon-white.svg/2048px-Globe_icon-white.svg.png"
        }
      />
      <span className="text-xs capitalize">{node.displayName}</span>
    </Flex>
  );
};
