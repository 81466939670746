import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { useAppDispatch } from "@/reduxHooks";

import { useGetNodesFromUsageInstanceIdMutation } from "..";
import { useLazyGetWorkflowQuery } from "../api/workflow-api";
import { setLastSavedTime } from "../redux";
import {
  setWorkflowRunId,
  setReferenceRunId,
  setWorkflowRunStatus,
} from "../redux/workflow-slice";
import { createReactflowObjectFromWorkflow } from "../utils/transform-response";

export const useRefreshWorkflow = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { setNodes, setEdges } = useReactFlow();

  const [getWorkflowDetails] = useLazyGetWorkflowQuery();
  const [getNodeList] = useGetNodesFromUsageInstanceIdMutation();

  const getNodeDetailsFromInstanceIds = useCallback(
    async (nodeUsageInstanceId: string[]) => {
      const nodeResponse = await getNodeList({
        analysisId: params["analysisId"] as string,
        nodeUsageIdList: nodeUsageInstanceId,
      }).unwrap();
      return nodeResponse.response.data!.nodes;
    },
    [getNodeList, params.analysisId]
  );

  const refetchWorkflow = useCallback(async () => {
    try {
      // Get fresh workflow data
      const workflowResponse = await getWorkflowDetails({
        workflowId: params.editorId!,
      }).unwrap();

      const workflow = workflowResponse.response.data?.workflows[0];
      if (!workflow?.workflowNodes) return false;

      // Extract nodeUsageInstanceIds
      const nodesInWorkflow = workflow.workflowNodes
        .flatMap((node) => node.nodeUsageInstanceId)
        .filter((node) => node !== null) as string[];

      // If nodes exist, get their details and create new workflow
      if (nodesInWorkflow.length > 0) {
        const nodeDetails =
          await getNodeDetailsFromInstanceIds(nodesInWorkflow);
        const flowObject = createReactflowObjectFromWorkflow(
          nodeDetails,
          workflow
        );
        setNodes(flowObject.configuredNodes);
        setEdges(flowObject.configuredEdges);
      } else {
        setNodes([]);
        setEdges([]);
      }

      // Update workflow state
      dispatch(setWorkflowRunId(workflow.workflowRunId as string));
      dispatch(setReferenceRunId(workflow.referenceRunId as string));
      dispatch(setWorkflowRunStatus(workflow.workflowStatus));
      dispatch(setLastSavedTime(workflow.lastSavedOn!));

      return true;
    } catch (error) {
      console.error("Error refreshing workflow:", error);
      return false;
    }
  }, [
    dispatch,
    getWorkflowDetails,
    getNodeDetailsFromInstanceIds,
    params.editorId,
    setNodes,
    setEdges,
  ]);

  return { refetchWorkflow };
};
