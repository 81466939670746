import { Box, Flex, Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useRef } from "react";
import {
  MdArrowRight,
  MdCancel,
  MdOutlineErrorOutline,
  MdOutlineMessage,
} from "react-icons/md";
import { useParams } from "react-router-dom";
import { Edge, Node, ReactFlowInstance } from "reactflow";

import { useShowToast } from "@/components/toast";
import { Button } from "@/design/components/button";
import { IconButton } from "@/design/components/icon-button";
import { NodeType } from "@/features/workflow-studio/types";
import { useAppDispatch, useAppSelector } from "@/reduxHooks.ts";
import { currentUserMetadata } from "@/slices/auth-slice";
import { ModalTypes, openModal } from "@/slices/modal-slice";
import { STATUS } from "@/utils/enums";

import { showPanel } from "../..";
import {
  useGetWorkflowRunStatusQuery,
  useLazyGetWorkflowQuery,
  useTerminateWorkflowMutation,
} from "../../api/workflow-api";
import { useRunWorkflow } from "../../hooks/useRunWorkflow";
import { useWorkflowRunTrigger } from "../../hooks/workflowRunTrigger";
import {
  currentWfActiveUser,
  currentWorkflowId,
  currentWorkflowRunId,
  getEditingAllowed,
  setEditingAllowed,
  setworkflowStatus,
  workflowStatus as wfRunStatus,
  workflowRunningStatus,
} from "../../redux/workflow-slice";
import {
  WorkflowNodeSchema,
  WorkflowSchema,
  WorkflowStatusSchema,
} from "../../types/workflow-types";
import {
  NODE_STATUS,
  NODE_STATUS_ICONS,
  WORKFLOW_PANELS,
} from "../../utils/constants";
import { validateNodeConfig } from "../../utils/validations";

export const RunAndLogButtons = ({
  nodes,
  edges,
  setNodes,
}: {
  nodes: Node[];
  edges: Edge[];
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
  reactFlowInstance: ReactFlowInstance;
}) => {
  const dispatch = useAppDispatch();
  const toast = useShowToast();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const params = useParams();
  const workflowId = useAppSelector(currentWorkflowId);
  const workflowRunId = useAppSelector(currentWorkflowRunId);
  const workflowRunStatus = useAppSelector(workflowRunningStatus);
  const wfnodeStatus = useAppSelector(wfRunStatus);

  const { runWorkflow, isLoading } = useRunWorkflow();

  const [terminateWFApi, { isLoading: isterminating }] =
    useTerminateWorkflowMutation();
  const [getWorkflowDetails] = useLazyGetWorkflowQuery();
  const isEditingAllowed = useAppSelector(getEditingAllowed) ?? false;
  const currentUserId = useAppSelector(currentUserMetadata)?.userId;
  const currentWFuser = useAppSelector(currentWfActiveUser);

  const workflowErrors = nodes.filter(
    (node: Node) => !validateNodeConfig(node, nodes, edges).isValid
  );
  const hasError = useMemo(() => workflowErrors.length > 0, [workflowErrors]);

  const isDisabled = useMemo(
    () => !isEditingAllowed || isLoading || nodes.length === 0,
    [isLoading, nodes.length, isEditingAllowed]
  );

  const isOtherUserEditing = useMemo(() => {
    if (currentWFuser?.user && currentUserId) {
      return currentWFuser?.user?.id !== currentUserId;
    }
    return false;
  }, [currentWFuser, currentUserId]);

  const isTerminateEnabled =
    ((isLoading || wfnodeStatus?.terminateWorkflowEnabled) &&
      !isOtherUserEditing) ??
    false;

  const isWorkflowInProgress =
    workflowRunStatus === NODE_STATUS.RUNNING ||
    workflowRunStatus === NODE_STATUS.CANCELLING;

  const shouldSkip =
    workflowId !== undefined && workflowId !== null && !isWorkflowInProgress;

  const { data, refetch } = useGetWorkflowRunStatusQuery(
    { workflowId: workflowId!, workflowRunId: workflowRunId ?? "" },
    {
      skip: shouldSkip,
      pollingInterval: 2000,
    }
  );

  const updateNodesAfterRunSuccess = async () => {
    const response = await getWorkflowDetails({
      workflowId: params.editorId!,
    }).unwrap();
    const wf = response.response.data?.workflows[0] as WorkflowSchema;
    const workflowNodes = wf.workflowNodes;
    if (wf.workflowStatus === NODE_STATUS.RUNNING || workflowNodes.length <= 0)
      return;
    setNodes((nds) =>
      nds.map((node) => {
        const newNode = workflowNodes.find(
          (n: WorkflowNodeSchema) =>
            n.workflowNodeId === (node.data as NodeType).workflowNodeId
        );
        if (newNode) {
          return {
            ...node,
            data: {
              ...node.data,
              nodeStatus: newNode.nodeStatus,
              isOutput: newNode.isOutput,
              outputName: newNode.outputName,
              outputState: newNode.outputState,
            } as NodeType,
          };
        }
        return node;
      })
    );
  };

  const updateNodeAfterRunStopped = (workflowStatus: WorkflowStatusSchema) => {
    setNodes((nds: Node[]) =>
      nds.map((node: Node) => {
        const nodeInfo = workflowStatus.workflowNodeStatus.find(
          (status) =>
            status.workflowNodeId === (node.data as NodeType).workflowNodeId
        );
        if (nodeInfo) {
          return {
            ...node,
            data: {
              ...node.data,
              nodeStatus: nodeInfo.nodeStatus,
            } as NodeType,
          };
        }
        return node;
      })
    );
  };

  // update store on data change
  useEffect(() => {
    if (data) {
      const workflowStatus = data?.response?.data
        ?.workflowAndNodeStatus as WorkflowStatusSchema;

      switch (workflowStatus?.workflowRunStatus) {
        case NODE_STATUS.SUCCESS:
          toast({
            title: "Workflow run completed successfully",
            status: "success",
          });
          dispatch(setEditingAllowed(true));
          updateNodesAfterRunSuccess().catch((e) => console.error(e));
          break;
        case NODE_STATUS.FAILED:
          toast({
            title: "Workflow Run Failed",
            status: "error",
          });
          dispatch(setEditingAllowed(true));
          updateNodeAfterRunStopped(workflowStatus);
          break;
        case NODE_STATUS.DEFAULT:
        case NODE_STATUS.CANCELLED:
          toast({
            title: "Workflow Run Cancelled",
            status: "warning",
          });
          dispatch(setEditingAllowed(true));
          updateNodeAfterRunStopped(workflowStatus);
          break;
      }
      dispatch(setworkflowStatus(workflowStatus));
    }
  }, [data, dispatch, toast]);

  const openworkflowErrorsModal = () => {
    dispatch(
      openModal({
        modalType: ModalTypes.WORKFLOW_ERRORS,
        modalProps: {
          nodes: nodes,
          edges: edges,
        },
      })
    );
  };

  useWorkflowRunTrigger(hasError, openworkflowErrorsModal);

  const onTerminate = () => {
    toast({
      title: "Workflow terminated Successfully",
      status: "success",
    });
    dispatch(setEditingAllowed(true));
  };

  const terminateWorkflow = async () => {
    try {
      const terminateResp = await terminateWFApi({
        workflowId: workflowId!,
        workflowRunId: workflowRunId!,
      }).unwrap();
      if (terminateResp.status === STATUS.SUCCESS) {
        onTerminate();
        refetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const openLogsPanel = () => {
    dispatch(showPanel({ panel: WORKFLOW_PANELS.LogsPanel }));
  };
  const workflowStatusText = useMemo(() => {
    if (workflowRunStatus === NODE_STATUS.CANCELLING) {
      return {
        disabled: "Cancelling Workflow",
        running: "Terminating Workflow",
      };
    }
    return {
      disabled: "Building workflow, please wait...",
      running: "Running Workflow",
    };
  }, [workflowRunStatus]);

  return (
    <Flex align="center" gap={1}>
      <IconButton
        aria-label="undo"
        variant="ghost"
        size="sm"
        colorScheme="dark"
        onClick={openLogsPanel}
        icon={<MdOutlineMessage />}
      />
      {isWorkflowInProgress ? (
        <Flex className="gap-2 pr-2" align="center" justify={"end"}>
          <Tooltip
            isDisabled={isTerminateEnabled}
            label={workflowStatusText.disabled}
            placement="bottom"
          >
            <Button
              className="ml-2"
              onClick={terminateWorkflow}
              size="sm"
              isDisabled={!isTerminateEnabled}
              variant="outline"
              isLoading={isLoading || isterminating}
              color="red.600"
              rightIcon={<MdCancel />}
            >
              Terminate Run
            </Button>
          </Tooltip>
          <Box className="text-gray-800 pb-1">
            {NODE_STATUS_ICONS[workflowRunStatus as NODE_STATUS].icon}
          </Box>
          <Box className="text-gray-800 text-xs">
            {workflowStatusText.running}
          </Box>
        </Flex>
      ) : (
        <Tooltip
          hasArrow
          isDisabled={isEditingAllowed}
          label="Cannot run workflow in View Mode."
          placement="bottom"
        >
          <Button
            ref={buttonRef}
            onClick={
              hasError
                ? openworkflowErrorsModal
                : runWorkflow.bind(null, workflowId!, {})
            }
            size="sm"
            className="disabled:cursor-not-allowed"
            variant={hasError ? "outline" : "solid"}
            isLoading={isLoading}
            isDisabled={isDisabled}
            colorScheme="dark"
            rightIcon={hasError ? <MdOutlineErrorOutline /> : <MdArrowRight />}
          >
            Run WorkFlow
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};
