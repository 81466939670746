import { Button, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { MdEdit, MdPublishedWithChanges, MdSync } from "react-icons/md";
import { MdsEditOffRound } from "react-icons-with-materialsymbols/mds";
import { useParams } from "react-router-dom";
import { ReactFlowInstance } from "reactflow";

import { ToastType, useShowToast } from "@/components/toast";
import { useAppDispatch, useAppSelector } from "@/reduxHooks";
import { currentUserMetadata } from "@/slices/auth-slice";

import {
  useEndEditAccessMutation,
  useRequestEditAccessMutation,
  useSaveWorkflowMutation,
} from "../../api";
import { useRefreshWorkflow } from "../../hooks/useRefreshWorkflow";
import {
  currentWfActiveUser,
  getEditingAllowed,
  getWorkflowState,
  setCurrentWfActiveUser,
  setEditingAllowed,
  workflowRunningStatus,
} from "../../redux";
import {
  ConvertFlowtoWorkflowPayload,
  NODE_STATUS,
  updateNodesAfterSave,
} from "../../utils";

const WorkflowActions = ({
  reactFlowInstance,
}: {
  reactFlowInstance: ReactFlowInstance | null;
}) => {
  const params = useParams();
  const toast = useShowToast(undefined, undefined, true);
  const dispatch = useAppDispatch();

  const [saveWorkflow, { isLoading: isSaving }] = useSaveWorkflowMutation();
  const [requestEditAccess, { isLoading: isRequesting }] =
    useRequestEditAccessMutation();
  const [endEditAccess, { isLoading: isEnding }] = useEndEditAccessMutation();
  const { refetchWorkflow } = useRefreshWorkflow();

  const isLoading = isRequesting || isEnding;
  const currentUserId = useAppSelector(currentUserMetadata)?.userId;
  const isEditingAllowed = useAppSelector(getEditingAllowed);
  const currentWFuser = useAppSelector(currentWfActiveUser);
  const workflowRunStatus = useAppSelector(workflowRunningStatus);
  const workflowState = useAppSelector(getWorkflowState);
  const [showSyncButton, setShowSyncButton] = useState(false);
  const [currentStateHash, setCurrentStateHash] = useState(
    workflowState?.stateHash
  );

  const isWorkflowRunning = workflowRunStatus === NODE_STATUS.RUNNING;

  const isOtherUserEditing = useMemo(() => {
    if (currentWFuser?.user && currentUserId) {
      return currentWFuser?.user?.id !== currentUserId;
    }
    return false;
  }, [currentWFuser, currentUserId]);

  useEffect(() => {
    if (isEditingAllowed || !isOtherUserEditing) {
      setShowSyncButton(false);
    }
    if (
      workflowState?.stateHash &&
      currentStateHash &&
      currentStateHash !== workflowState.stateHash
    ) {
      setShowSyncButton(true);
    }
    setCurrentStateHash(workflowState?.stateHash);
  }, [workflowState?.stateHash, currentStateHash]);

  const handleSyncChanges = async () => {
    const success = await refetchWorkflow();
    if (success) {
      setShowSyncButton(false);
    }
  };

  const setNodes = reactFlowInstance?.setNodes;

  const convertTOPayload = () => {
    const obj = reactFlowInstance?.toObject();
    saveWorkflow({
      analysisId: params.analysisId!,
      workflow: ConvertFlowtoWorkflowPayload(obj!),
      workflowId: params.editorId!,
    })
      .unwrap()
      .then((res) => {
        const newNodes = res.response.data!.workflows[0].workflowNodes;
        setNodes?.((currentNodes) =>
          updateNodesAfterSave(newNodes, currentNodes)
        );
        toast({
          title: "Workflow saved successfully",
          status: ToastType.Success,
        });
      })
      .catch((err) => {
        toast({
          title: "Failed to save workflow",
          status: ToastType.Error,
        });
        console.log(err);
      });
  };

  const rfobj = () => {
    console.log(reactFlowInstance?.toObject());
  };

  const onEditClick = () => {
    requestEditAccess({
      analysisId: params.analysisId!,
    })
      .unwrap()
      .then((res) => {
        toast({
          title: res?.data?.results?.message,
          status: ToastType.Success,
        });
        // optimistically update the current user as active to
        // allow setting setEditingAllowed to true in slice
        dispatch(
          setCurrentWfActiveUser({
            isCurrentUser: true,
            user: null,
            status: null,
          })
        );
        dispatch(setEditingAllowed(true));
      })
      .catch((err) => {
        toast({
          title: "Failed to request edit access",
          status: ToastType.Error,
        });
      });
  };

  const onEndEditClick = () => {
    endEditAccess({
      analysisId: params.analysisId!,
    })
      .unwrap()
      .then((res) => {
        toast({
          title: "Edit session terminated",
          status: ToastType.Success,
        });
        dispatch(setEditingAllowed(false));
        dispatch(
          setCurrentWfActiveUser({
            user: null,
            status: "",
            isCurrentUser: false,
          })
        );
      })
      .catch((err) => {
        toast({
          title: "Failed to end edit access",
          status: ToastType.Error,
        });
      });
  };

  return (
    <Flex
      className={
        "absolute top-5 right-5 border border-gray-400 rounded-sm bg-white p-1 shadow-noblur flex gap-1"
      }
    >
      {/* <Button onClick={rfobj} size="sm">
        RF Debug Log
      </Button> */}
      {!isEditingAllowed && showSyncButton && (
        <IconButton
          aria-label="Sync Changes"
          colorScheme="green"
          icon={<MdSync size={16} />}
          onClick={handleSyncChanges}
          size="sm"
        />
      )}
      {!isEditingAllowed ? (
        <Tooltip
          hasArrow
          isDisabled={!isOtherUserEditing}
          label={`The workflow is used by ${currentWFuser?.user?.displayName}.`}
          placement={"bottom"}
        >
          <Button
            colorScheme="dark"
            isDisabled={isOtherUserEditing || isLoading || isWorkflowRunning}
            isLoading={isLoading}
            leftIcon={<MdEdit size={14} />}
            onClick={onEditClick}
            size="sm"
          >
            Edit Workflow
          </Button>
        </Tooltip>
      ) : (
        <Button
          className="!bg-white"
          colorScheme="dark"
          isDisabled={isLoading}
          isLoading={isLoading}
          leftIcon={<MdsEditOffRound className="stroke-[22]" fontSize={14} />}
          onClick={onEndEditClick}
          size="sm"
          variant={"outline"}
        >
          Stop Editing
        </Button>
      )}
      {isEditingAllowed && (
        <Button
          colorScheme="dark"
          isDisabled={!isEditingAllowed}
          isLoading={isSaving}
          leftIcon={<MdPublishedWithChanges size={14} />}
          onClick={convertTOPayload}
          size="sm"
        >
          Save Workflow
        </Button>
      )}
      {/*  <Button size="sm" onClick={exportobjtoFlow}>
      Export Obj to Flow
    </Button> */}
    </Flex>
  );
};

export default WorkflowActions;
