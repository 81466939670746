import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { MdOutlineCloud } from "react-icons/md";
import { SlRefresh } from "react-icons/sl";

import { Icon } from "@/design/components/icon";
import {
  isAutoSaving,
  workflowLastSavedTime,
} from "@/features/workflow-studio/redux";
import { useAppSelector } from "@/reduxHooks.ts";

export const SaveStatus = () => {
  const isSaving = useAppSelector(isAutoSaving);
  const lastSavedTime = useAppSelector(workflowLastSavedTime) ?? "";

  const formatLastSaved = () => {
    if (!lastSavedTime) return "";
    const saveTime = new Date(lastSavedTime);
    return `Last saved ${saveTime.toLocaleTimeString()}`;
  };

  return (
    <Tooltip
      className={"!bg-gray-900 !-top-4 text-sm !rounded-md"}
      sx={{
        ".chakra-tooltip__arrow": {
          backgroundColor: "#232323 !important",
        },
      }}
      hasArrow
      isDisabled={lastSavedTime === ""}
      label={formatLastSaved()}
      placement={"bottom"}
      shouldWrapChildren
    >
      <Flex className="p-3 cursor-pointer" align="center" gap={2}>
        {isSaving ? (
          <Icon as={SlRefresh} className="animate-spin" size="sm" />
        ) : (
          <Icon as={MdOutlineCloud} size="sm" />
        )}

        <Text className="underline decoration-dotted" fontSize="xs">
          {isSaving ? "Saving..." : "Saved"}
        </Text>
      </Flex>
    </Tooltip>
  );
};
