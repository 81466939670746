import { ApiResponse } from "@/types";
import { keysToSnake } from "@/utils/snakeCaseConverter";

import {
  SaveWorkflowSchema,
  WFActiveUserResponseSchema,
  WFEditRequestResponse,
  WorkflowResponseSchema,
  WorkflowStatusResponseSchema,
} from "../types/workflow-types";

import { workflowEditorApi } from "./api";
import { WorkflowEndpoints } from "./api-endpoints";

export const workflowManagerApi = workflowEditorApi.injectEndpoints({
  // TODO : add Tags to each endpoint
  endpoints: (build) => ({
    getWFActiveUser: build.query<
      WFActiveUserResponseSchema,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: WorkflowEndpoints.wfEditAccess(analysisId),
        // url: `http://localhost:3017/workflow-mgmt/api/v1.0/analysis/${analysisId}/workflow/user/`,
        method: "GET",
        ignoreBaseUrl: true,
      }),
    }),
    requestEditAccess: build.mutation<
      WFEditRequestResponse,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: WorkflowEndpoints.wfEditAccess(analysisId),
        // url: `http://localhost:3017/workflow-mgmt/api/v1.0/analysis/${analysisId}/workflow/user/`,
        method: "PUT",
        ignoreBaseUrl: true,
        data: {
          action: "claim",
        },
      }),
    }),
    endEditAccess: build.mutation<
      WFEditRequestResponse,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: WorkflowEndpoints.wfEditAccess(analysisId),
        // url: `http://localhost:3017/workflow-mgmt/api/v1.0/analysis/${analysisId}/workflow/user/`,
        method: "PUT",
        ignoreBaseUrl: true,
        data: {
          action: "end",
        },
      }),
    }),
    getworkflowsFromAnalysis: build.query<
      ApiResponse<WorkflowResponseSchema>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => ({
        url: WorkflowEndpoints.getWorkflowFromAnalysis(analysisId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
    }),
    createWorkflow: build.mutation<
      ApiResponse<WorkflowResponseSchema>,
      { analysisId: string }
    >({
      query: ({ analysisId }) => {
        return {
          url: WorkflowEndpoints.createWorkflowFromAnalysis(analysisId),
          method: "POST",
          ignoreBaseUrl: true,
          headers: {
            "client-id": import.meta.env.VITE_CLIENT_ID,
          },
          data: {
            analysis_id: analysisId,
            user_id: import.meta.env.VITE_USER_ID,
          },
        };
      },
    }),
    getWorkflow: build.query<
      ApiResponse<WorkflowResponseSchema>,
      { workflowId: string }
    >({
      query: ({ workflowId }) => ({
        url: WorkflowEndpoints.getWorkflow(workflowId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
      keepUnusedDataFor: 0,
    }),
    saveWorkflow: build.mutation<
      ApiResponse<WorkflowResponseSchema>,
      {
        analysisId: string;
        workflowId: string;
        workflow: any;
      }
    >({
      query: ({ analysisId, workflowId, workflow }) => ({
        url: WorkflowEndpoints.saveWorkflow(workflowId),
        method: "PUT",
        data: {
          analysis_id: analysisId,
          user_id: import.meta.env.VITE_USER_ID,
          ...keysToSnake(workflow),
        },
        headers: {
          "client-id": import.meta.env.VITE_CLIENT_ID,
        },
        ignoreBaseUrl: true,
      }),
    }),
    markOutput: build.mutation<
      ApiResponse<WorkflowResponseSchema>,
      {
        analysisId: string;
        workflowId: string;
        workflow: SaveWorkflowSchema;
      }
    >({
      query: ({ analysisId, workflowId, workflow }) => ({
        url: WorkflowEndpoints.markOutput(workflowId),
        method: "PUT",
        data: {
          analysis_id: analysisId,
          user_id: import.meta.env.VITE_USER_ID,
          ...keysToSnake(workflow),
        },
        headers: {
          "client-id": import.meta.env.VITE_CLIENT_ID,
        },
        ignoreBaseUrl: true,
      }),
    }),
    unMarkOutput: build.mutation<
      ApiResponse<WorkflowResponseSchema>,
      {
        analysisId: string;
        workflowId: string;
        workflow: any;
      }
    >({
      query: ({ analysisId, workflowId, workflow }) => ({
        url: WorkflowEndpoints.unMarkOutput(workflowId),
        method: "PUT",
        data: {
          analysis_id: analysisId,
          user_id: import.meta.env.VITE_USER_ID,
          ...keysToSnake(workflow),
        },
        headers: {
          "client-id": import.meta.env.VITE_CLIENT_ID,
        },
        ignoreBaseUrl: true,
      }),
    }),
    runWorkflow: build.mutation<
      ApiResponse<WorkflowResponseSchema>,
      { analysisId: string; workflowId: string; workflow: any }
    >({
      query: ({ analysisId, workflowId, workflow }) => ({
        url: WorkflowEndpoints.runWorkflow(workflowId),
        method: "PUT",
        data: {
          analysis_id: analysisId,
          user_id: import.meta.env.VITE_USER_ID,
          ...keysToSnake(workflow),
        },
        headers: {
          "client-id": import.meta.env.VITE_CLIENT_ID,
        },
        ignoreBaseUrl: true,
      }),
    }),
    getWorkflowRunStatus: build.query<
      ApiResponse<WorkflowStatusResponseSchema>,
      { workflowId: string; workflowRunId: string }
    >({
      query: ({ workflowId, workflowRunId }) => ({
        url: WorkflowEndpoints.getWorkflowStatus(workflowId, workflowRunId),
        method: "GET",
        ignoreBaseUrl: true,
      }),
    }),
    terminateWorkflow: build.mutation<
      ApiResponse<WorkflowResponseSchema>,
      { workflowId: string; workflowRunId: string }
    >({
      query: ({ workflowId, workflowRunId }) => ({
        url: WorkflowEndpoints.terminateWorkflow(workflowId, workflowRunId),
        method: "POST",
        ignoreBaseUrl: true,
      }),
    }),
  }),
});

export const {
  useGetWorkflowQuery,
  useLazyGetWorkflowQuery,
  useGetWFActiveUserQuery,
  useLazyGetWFActiveUserQuery,
  useRequestEditAccessMutation,
  useEndEditAccessMutation,
  useGetworkflowsFromAnalysisQuery,
  useLazyGetworkflowsFromAnalysisQuery,
  useSaveWorkflowMutation,
  useCreateWorkflowMutation,
  useGetWorkflowRunStatusQuery,
  useRunWorkflowMutation,
  useMarkOutputMutation,
  useUnMarkOutputMutation,
  useTerminateWorkflowMutation,
} = workflowManagerApi;
