import { Flex, IconButton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import { UserDropdown } from "@/components/user-dropdown";
import { TitleBar, TitleBarHeader } from "@/design/components/title-bar";
import { TitleBarProfile } from "@/design/components/title-bar/title-bar-profile.tsx";
import { setStudioAnalysis } from "@/features/data-manager/redux";
import { useLazyGetworkflowsFromAnalysisQuery } from "@/features/workflow-studio";
import { SaveStatus } from "@/features/workflow-studio/components/save-status";
import { setWorkFlowId } from "@/features/workflow-studio/redux";
import { useGetSingleAnalysisQuery } from "@/features/ws-manager";
import { AnalysesSchema } from "@/features/ws-manager/types";
import { useAppDispatch } from "@/reduxHooks";

import { NavbarTitle } from "./navbar-title";

export const EditorNavbar = () => {
  const { state } = useLocation();
  const { analysisId } = useParams();
  const navigate = useNavigate();
  const [analysisDetails, setAnalysisDetails] = useState<
    AnalysesSchema | undefined
  >();

  const { data } = useGetSingleAnalysisQuery(
    {
      id: analysisId!,
    },
    {
      skip: !analysisId,
    }
  );
  const [getWf] = useLazyGetworkflowsFromAnalysisQuery();

  const dispatch = useAppDispatch();

  const fetchWfFromAnalysisId = async () => {
    try {
      const res = await getWf({ analysisId: analysisId! }).unwrap();
      const workflowId = res.response.data?.workflows[0]?.workflowId;
      if (!workflowId) return; //TODO: Catch error
      dispatch(setWorkFlowId(workflowId));
      // Update URL with correct workflow ID
      if (window.location.pathname.includes(`/editor/`)) {
        navigate(`/analysis/${analysisId}/editor/${workflowId}`, { replace: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // commenting this currently since workflow is disabled
    fetchWfFromAnalysisId();

    if (!data) return;

    dispatch(
      setStudioAnalysis(
        data.response.data?.analyses[0] as Partial<AnalysesSchema>
      )
    );
    setAnalysisDetails(data.response.data?.analyses[0]);
  }, [state, analysisId, data, getWf]);

  return (
    <TitleBar className="z-[5]">
      <TitleBarHeader w="100%" className="justify-between flex">
        <Flex>
          <NavbarTitle analysis={analysisDetails} />
          {window.location.pathname.includes('/editor/') && <SaveStatus />}
        </Flex>
        {/* 
        <IconButton
          sx={{ alignSelf: "end" }}
          fontSize={"24px"}
          aria-label="Notification"
          colorScheme="dark"
          icon={<Notification />}
          variant="ghost"
        />
        */}
      </TitleBarHeader>
      <TitleBarProfile
        sx={{
          "::before": {
            content: '""',
            position: "absolute",
            left: "0",
            width: "1px",
            height: "50%",
            background: "gray.200",
          },
        }}
      >
        <UserDropdown />
      </TitleBarProfile>
    </TitleBar>
  );
};
