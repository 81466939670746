import { Stack } from "@chakra-ui/react";
import clsx from "clsx";
import { Edge, ReactFlowInstance, Node } from "reactflow";

import { ImportExportButtons } from "./import-export-buttons";
import { RunAndLogButtons } from "./run-log-buttons";

export const StatusBar = ({
  nodes,
  edges,
  reactFlowInstance,
  setNodes,
}: {
  nodes: Node[];
  edges: Edge[];
  reactFlowInstance: ReactFlowInstance;
  setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
}) => {
  return (
    <Stack
      className={clsx(
        "absolute top-5 left-5 border border-gray-400",
        "rounded-sm bg-white p-1 shadow-noblur",
        "flex gap-1"
      )}
      direction="row"
    >
      <ImportExportButtons />
      <div className="w-0.5 bg-gray-200" />
      <RunAndLogButtons
        nodes={nodes}
        setNodes={setNodes}
        edges={edges}
        reactFlowInstance={reactFlowInstance}
      />
    </Stack>
  );
};
